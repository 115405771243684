import 'swiper/css';
import 'swiper/css/scrollbar';
import './Reviews.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';

export function Reviews() {
    return <div>
        <div className="uppercase mb-10">[Відгуки]</div>
        <div className="-mx-[14px]">
            <div className="Scrollbar-container h-1"></div>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                modules={[Scrollbar]}
                scrollbar={{el: ".Scrollbar-container"}}
            >
                <SwiperSlide>
                    <img className="w-full" src="./reviews/review-1.png" alt="review 1 -2.5kg"/>
                    {/*<div className="Review-item absolute bottom-2 right-2 text-regular">-2.5 кг</div>*/}
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-full" src="./reviews/review-text-1.png" alt="review 1 -2.5kg"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-full" src="./reviews/review-2-1.png" alt="review 1 -2.5kg"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-full" src="./reviews/review-2.png" alt="review 1 -2.5kg"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-full" src="./reviews/review-text-2.png" alt="review 1 -2.5kg"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-full" src="./reviews/review-3.png" alt="review 1 -2.5kg"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-full" src="./reviews/review-text-3.png" alt="review 1 -2.5kg"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className="w-full" src="./reviews/review-4.png" alt="review 1 -2.5kg"/>
                </SwiperSlide>
            </Swiper>
        </div>
    </div>
}
