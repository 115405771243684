import { Button, styled } from '@mui/material';

const CustomButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'uppercase',
    fontSize: 26,
    padding: '16px 18px',
    border: '1px solid',
    lineHeight: '20px',
    backgroundColor: '#3E2C22',
    borderColor: '#3E2C22',
    color: "#F0ECE1",
    fontFamily: [
        'Cormorant Unicase',
    ].join(','),
    '&:hover': {
        backgroundColor: '#3E2C22',
        borderColor: '#3E2C22',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#3E2C22',
        borderColor: '#3E2C22',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #3E2C22',
    },
});


export function JoinButton() {
    return <CustomButton variant="contained" size="large" onClick={() => {
        window.location.href = "https://secure.wayforpay.com/payment/fitnessdays";
    }}>Приєднатися</CustomButton>
}
