import { Box, SwipeableDrawer, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useState } from 'react';

function MenuIcon() {
    return (
        <svg width="58" height="21" viewBox="0 0 58 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.00390625" y1="0.5" x2="58.0039" y2="0.5" stroke="#3E2C22"/>
            <line y1="10.5" x2="58" y2="10.5" stroke="#3E2C22"/>
            <line x1="0.00390625" y1="20.5" x2="58.0039" y2="20.5" stroke="#3E2C22"/>
        </svg>

    )
}

function Menu() {
    const [open, setOpen] = useState(false);

    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setOpen(open);
            };

    const DrawerList = (
        <Box sx={{ width: 350 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                {[['Програма', "AboutCourse"],
                    ['для кого', "ForWhom"],
                    ['відгуки', "Reviews"],
                    ['контакти', "Questions"]].map(([text, id], index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton onClick={() => {
                            document.getElementById(id)?.scrollIntoView({
                                behavior: 'smooth'
                            });
                        }}>
                            <ListItemText primary={text} className="uppercase"/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <div>
            <IconButton aria-label="delete" onClick={toggleDrawer(true)}>
                <MenuIcon/>
            </IconButton>
            <SwipeableDrawer anchor="left" open={open} onOpen={toggleDrawer(true)} onClose={toggleDrawer(false)}>
                {DrawerList}
            </SwipeableDrawer>
        </div>
    )
}

export function Header() {
    return (
        <header className="z-50 top-0 -mx-[14px] pt-[30px] p-2 bg-[#F0ECE1] flex flex-row justify-between items-center">
            <div><Menu/></div>
            <div>
                <a href="https://www.instagram.com/chv.lera/" target="_blank" className="flex flex-row items-center"
                   rel="noreferrer">CHV.LERA</a>
            </div>
        </header>
    )
}
