import { Arrow } from './ForWhom';

export function Questions() {
    return (
        <div>
            <div className="uppercase mb-10">[Виникли питання?]</div>
            <div>
                <div className="flex flex-row items-center">
                    <a href="https://www.instagram.com/chv.lera/" target="_blank" className="flex flex-row items-center" rel="noreferrer">
                        <div className="float-left flex items-center h-[20px] mr-1"><Arrow/></div>
                        <div className="text-regular">instagram</div>
                    </a>
                </div>
                <div className="flex flex-row items-center">
                    <a href="https://t.me/chv_lera" target="_blank" className="flex flex-row items-center" rel="noreferrer">
                        <div className="float-left flex items-center h-[20px] mr-1"><Arrow/></div>
                        <div className="text-regular">telegram</div>
                    </a>
                </div>
            </div>
        </div>
    )
}
