import React from 'react';
import { Header } from './Header/Header';
import { Banner } from './Banner/Banner';
import { Content } from './Content/Content';
import { createTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import { Footer } from './Footer/Footer';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3E2C22',
        },
        background: {
            default: '#F0ECE1',
        },
    },
    typography: {
        fontFamily: 'Cormorant Unicase, serif',
        fontSize: 18,
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F0ECE1', // Set Paper background globally
                },
            },
        },
    },
});

function Body() {
    const matches = useMediaQuery('(min-width:600px)');

    if (matches) {
        return <div className="max-w-[600px] mx-auto">
            <ThemeProvider theme={theme}>
                <Header/>
                <Banner />
                <Content />
                <Footer />
            </ThemeProvider>
        </div>
    }

    return <ThemeProvider theme={theme}>
        <Header/>
        <Banner />
        <Content />
        <Footer />
    </ThemeProvider>
}
function App() {
    return (
        <Body />
    );
}

export default App;
