import "./Footer.css";

export function Footer() {
    return (
        <footer className="Section -mx-[14px] py-10 px-[14px] bg-[#3E2C22] text-white">
            <div>
                <div className="Footer-text">FITNESS</div>
                <div className="flex flex-row">
                    <div className="Footer-text">DAYS</div>
                    <div className="uppercase leading-[1.2rem] text-[1rem] mt-2 ml-2">
                        <div>[Кожна дія</div>
                        <div>приносить</div>
                        <div>результат]</div>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <div className="flex flex-row">
                    <div className="flex flex-col gap-2">
                        {[
                            ['про автора', "AboutAuthor"],
                            ['про інтенсив', "AboutCourse"]
                        ].map(([text, id]) => {
                            return <div className="text-regular text-small uppercase text-nowrap" onClick={() => {
                                document.getElementById(id)?.scrollIntoView({
                                    behavior: 'smooth'
                                });
                            }}>{text}</div>
                        })}
                    </div>
                    <div className="ml-5 flex flex-col gap-2">
                        {[
                            ['для кого', "ForWhom"],
                            ['faq', "FAQ"]].map(([text, id]) => {
                            return <div className="text-regular text-small uppercase text-nowrap gap-y-2" onClick={() => {
                                document.getElementById(id)?.scrollIntoView({
                                    behavior: 'smooth'
                                });
                            }}>{text}</div>
                        })}
                    </div>
                </div>
                <div className="flex flex-col mt-5">
                    <div className="text-regular text-small uppercase"><a href="https://docs.google.com/document/d/1T-8BwEUsbjX_7bEIw60BBtLuKl_mPlu9/edit" target="_blank" rel="noreferrer">політика конфіденційності</a></div>
                    <div className="text-regular text-small uppercase mt-2"><a href="https://docs.google.com/document/d/1Ob0yGYOPfP-2W6STpQSv7xjTz84pb_vx/edit" target="_blank" rel="noreferrer">Договір оферти</a></div>
                </div>
            </div>
        </footer>
    )
}
