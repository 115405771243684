const items = [
    "Привіт! Я Лєра і саме я буду твоїм куратором, тренером, нутріціологом та мотиватором наступні три тижні",
    "- більше 4-х років я працюю фітнес тренером",
    "- два роки у сфері нутріціологіі",
    "- кандидат у майстри спорту України з художньої гімнастики",
    "- освіта: Національний університет фізичного виховання та спорту України",
    "- 15+ різноманітних навчать",
    "- допомогла схуднути і розібратися з харчуванням більше ніж 100 дівчатам та хлопцям",
];

export function AboutAuthor() {
    return <div>
        <div className="uppercase mb-10">[автор Інтенсиву]</div>
        <div className="relative -mx-[14px] mt-5 text-regular text-white">
            <img
                className="w-full min-h-[500px]"
                alt="Привіт! Я Лєра і саме я буду твоїм куратором, тренером, нутріціологом та мотиватором наступні три тижні"
                src="./about-author-banner.png"/>
            <div className="absolute top-0 py-10 px-[14px] gap-5 flex flex-col">
                <div className="absolute top-3 left-1 w-[123px] h-[123px] rounded-full bg-[#3E2C22] z-10"></div>
                <div className="relative z-20 gap-5 flex flex-col">
                    {items.map((item, i) => (
                        <div key={i}> {item}</div>
                    ))}
                </div>
            </div>
        </div>
    </div>;
}
