import { JoinButton } from '../JoinButton/JoinButton';

const items = [
    "ти постійно шукаєш причини свого хаотичного харчування та переїдань, кожного разу починаєш з понеділка",
    "ти хочеш мати здорове тіло, без обмежень та надмірних тренувань",
    "ти прагнеш зрозуміти більше про збалансоване харчування, покращити самопочуття",
    "тобі не вистачає мотивації та знань досягнути поставленої мети",
]

export function Arrow() {
    return <svg width="38" height="8" viewBox="0 0 38 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.980465 3.5L0.480465 3.5L0.480473 4.5L0.980473 4.5L0.980465 3.5ZM37.334 4.35325C37.5293 4.15798 37.5293 3.8414 37.334 3.64614L34.152 0.464186C33.9567 0.268926 33.6402 0.268929 33.4449 0.464192C33.2496 0.659456 33.2496 0.976039 33.4449 1.1713L36.2734 3.9997L33.445 6.82815C33.2497 7.02342 33.2497 7.34 33.445 7.53526C33.6402 7.73052 33.9568 7.73052 34.1521 7.53525L37.334 4.35325ZM0.980473 4.5L36.9805 4.4997L36.9805 3.4997L0.980465 3.5L0.980473 4.5Z"
            fill="#3E2C22"/>
    </svg>
}


export function ForWhom() {
    return <div>
        <div className="uppercase mb-10">[Для кого]</div>
        <div className="text-regular">
            <div className="mb-3">якщо:</div>
            {items.map((text, i) => {
                return <div key={i} className="py-2">
                    <div>
                        <div className="float-left flex items-center h-[20px] mr-1"><Arrow/></div>
                        <div>{text}</div>
                    </div>
                </div>
            })}
        </div>
        <div className="my-10">
            <JoinButton/>
        </div>
    </div>
}
