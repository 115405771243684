import { ForWhom } from './ForWhom';
import { StartPathBanner } from './StartPathBanner';
import { AboutCourse } from './AboutCourse';
import { AboutAuthor } from './AboutAuthor';
import { Reviews } from './Reviews';
import { FAQ } from './FAQ';
import { Questions } from './Questions';

export function Content() {
    return <main>
        <div id="ForWhom" className="Section">
            <ForWhom />
        </div>
        <div id="StartPathBanner" className="Section">
            <StartPathBanner />
        </div>
        <div id="AboutCourse" className="Section">
            <AboutCourse />
        </div>
        <div id="AboutAuthor" className="Section">
            <AboutAuthor />
        </div>
        <div id="Reviews" className="Section">
            <Reviews />
        </div>
        <div id="FAQ" className="Section">
            <FAQ />
        </div>
        <div id="Questions" className="Section">
            <Questions />
        </div>
    </main>
}
